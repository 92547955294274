<section id="development">
  <div class="row">
    <div class="col-5">
      <h5 class="display-5 fw-normal"><strong>Development</strong></h5>
    </div>
  </div>

  <div class="mt-5">
    <h3><strong>Requirements</strong></h3>

    <div class="row gx-5 gy-3 fw-bold mt-4">
      <div class="col-auto">
        <div class="text-primary mb-3">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#check-circle-fill"/>
          </svg>
          <span class="text-body">
            .Net 6 SDK
          </span>
        </div>
        <a href="https://dotnet.microsoft.com/en-us/download/visual-studio-sdks" target="_blank" class="btn btn-outline-primary border-2">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#cloud-download"/>
          </svg>
          Download
        </a>
      </div>

      <div class="col-auto">
        <div class="text-primary mb-3">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#check-circle-fill"/>
          </svg>
          <span class="text-body">
            Node.js 16.x
          </span>
        </div>
        <a href="https://nodejs.org/en/" target="_blank" class="btn btn-outline-primary border-2">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#cloud-download"/>
          </svg>
          Download
        </a>
      </div>

      <div class="col-auto">
        <div class="text-primary mb-3">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#check-circle-fill"/>
          </svg>
          <span class="text-body">
            OpenJDK
          </span>
        </div>
        <a href="https://docs.microsoft.com/en-us/java/openjdk/download" target="_blank" class="btn btn-outline-primary border-2">
          <svg class="bi" fill="currentColor">
            <use xlink:href="assets/icons.svg#cloud-download"/>
          </svg>
          Download
        </a>
      </div>
    </div>
  </div>

  <div class="row g-0 mt-5">
    <div class="col-auto d-none d-md-block">
      <h3 class="fw-bold number">1</h3>
    </div>
    <div class="col">
      <h3 class="number-text fw-bold d-flex align-items-center">
        Clone or download repository
      </h3>
      <div class="code">
        git clone https://github.com/fschick/TimeTracking.git<br>
        cd TimeTracking
      </div>
    </div>
  </div>

  <div class="row g-0 mt-5">
    <div class="col-auto d-none d-md-block">
      <h3 class="fw-bold number">2</h3>
    </div>
    <div class="col">
      <h3 class="number-text fw-bold d-flex align-items-center">
        Install NPM packages
      </h3>
      <div class="code">
        cd FS.TimeTracking.UI.Angular<br>
        npm install<br>
        cd ../..
      </div>
    </div>
  </div>

  <div class="row g-0 mt-5">
    <div class="col-auto d-none d-md-block">
      <h3 class="fw-bold number">3</h3>
    </div>
    <div class="col">
      <h3 class="number-text fw-bold d-flex align-items-center">
        Start server
      </h3>
      <div class="code">
        dotnet run --project FS.TimeTracking/FS.TimeTracking/FS.TimeTracking.csproj
      </div>
    </div>
  </div>

  <div class="row g-0 mt-5">
    <div class="col-auto d-none d-md-block">
      <h3 class="fw-bold number">4</h3>
    </div>
    <div class="col">
      <h3 class="number-text fw-bold d-flex align-items-center">
        Start UI
      </h3>
      <div class="code">
        cd FS.TimeTracking.UI/FS.TimeTracking.UI.Angular<br>
        npm run start
      </div>
    </div>
  </div>

</section>
