<section id="features">
  <h5 class="display-5 fw-normal feature-header"><strong>Features</strong></h5>

  <div class="row text-center g-5">
    <div class="feature col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-4 offset-xl-0">
      <div class="easy-tracking">
        <img class="w-50" src="assets/images/feature-easy-tracking.svg" alt="Easy Tracking">
        <div class="mt-5">
          <h3 class="feature-title">Track times quickly and easily</h3>
          <p>Track times live or add work. No problem at all and entered in seconds. So you always keep track and time management becomes a piece of cake!</p>
        </div>
      </div>
    </div>
    <div class="feature col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-4 offset-xl-0">
      <div class="charts">
        <img class="w-50" src="assets/images/feature-analysis.svg" alt="Charts">
        <div class="mt-5">
          <h3 class="feature-title">Detailed analyses and reports</h3>
          <p>With numerous clear evaluations and charts, important information such as available or already worked time is only a click away at any time.</p>
        </div>
      </div>
    </div>
    <div class="feature col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-4 offset-xl-0">
      <div class="gdpdr">
        <img class="w-50" src="assets/images/feature-gdpdr.svg" alt="GDPDR">
        <div class="mt-5">
          <h3 class="feature-title">Secure and privacy compliant</h3>
          <p>Your sensitive data is not stored in any cloud or shared with third parties. The application runs locally or on your server and no one has access to your information.</p>
        </div>
      </div>
    </div>
  </div>
</section>
