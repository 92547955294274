<span *ngIf="isDevelopment" class="breakpoint">
  <span class="d-inline d-sm-none">&nbsp;(xs)</span>
  <span class="d-none d-sm-inline d-md-none">&nbsp;(sm)</span>
  <span class="d-none d-md-inline d-lg-none">&nbsp;(md)</span>
  <span class="d-none d-lg-inline d-xl-none">&nbsp;(lg)</span>
  <span class="d-none d-xl-inline d-xxl-none">&nbsp;(xl)</span>
  <span class="d-none d-xxl-inline">&nbsp;(xxl)</span>
</span>

<nav class="navbar navbar-expand-xl navbar-light bg-transparent">
  <div class="container-fluid">
    <a class="navbar-brand fs-4" routerLink="">
      <span class="icon">
        <svg class="bi me-2 text-white fs-5" fill="currentColor">
          <use xlink:href="assets/icons.svg#calendar-week"/>
        </svg>
      </span>
      <b class="brand-text text-primary">TimeTracking</b>
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mt-3 mt-lg-0" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#features">Features</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#installation">Getting&nbsp;started</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#configuration">Configuration</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#development">Development</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="https://www.schick-software.de/#!imprint" target="_blank">Legal Notice</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <router-outlet></router-outlet>
</div>
