<section id="headline">
  <div class="row align-items-center">
    <div class="col-12 col-xl-5">

      <h1 class="display-3 fw-normal lh-base text-center text-xl-start">
        A <strong>fast</strong> and <br class="d-none d-xl-inline"> <strong>straightforward</strong> tool for <span class="text-uppercase text-primary"><strong>your</strong></span> time recording
      </h1>

    </div>
    <div class="col-12 col-xl-7">
      <img class="screens" src="assets/images/screens.png" alt="Screenshots">
    </div>
  </div>

  <div class="row g-3 justify-content-center justify-content-xl-start">
    <div class="col-auto">
      <a class="btn btn-primary text-white"
         href="https://github.com/fschick/TimeTracking/releases/latest"
         target="_blank">
        <svg class="bi" fill="currentColor">
          <use xlink:href="assets/icons.svg#github"/>
        </svg>
        Download from GitHub
      </a>
    </div>
    <div class="col-auto">
      <a href="#installation" class="btn btn-outline-primary border-primary border-2">
        Getting&nbsp;started
      </a>
    </div>
  </div>

  <h5 class="mt-4 text-center text-xl-start">
    <strong>
      <a href="https://demo.timetracking.schick-software.de/" target="_blank">
        <svg class="bi mx-2" fill="currentColor">
          <use xlink:href="assets/icons.svg#box-arrow-up-right"/>
        </svg>
        Try demo
      </a>
    </strong>
  </h5>
</section>
